import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { PandaHead } from '../../components/PandaHead';
import { Text } from '../../components/Reusable';
import { useBottomMenu } from '../../hooks/useBottomMenu';
import { useTheme } from '../../hooks/useTheme';
import { ColorThemeProps } from '../../theme';
import { storage } from '../../utils/storage';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const TitleText = styled.h1<ColorThemeProps>`
  font-size: 2rem;
  color: ${({ theme }) => theme.white};
  font-family: poppins, Helvetica, sans-serif;
  font-weight: 600;
  margin: 0.25rem 0;
  text-align: center;
`;



const InstallButton = styled(Button) <ColorThemeProps>`
  margin-top: 1rem;
`;

export const Start = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [showStart, setShowStart] = useState(false);
  const { hideMenu, showMenu } = useBottomMenu();
  const [deferredPrompt, setDeferredPrompt] = useState<any | null>(null); // Define state for deferredPrompt

  useEffect(() => {
    hideMenu();

    return () => {
      showMenu();
    };
  }, [hideMenu, showMenu]);

  useEffect(() => {
    storage.get(['encryptedKeys', 'connectRequest'], (result) => {
      if (result?.connectRequest) {
        setShowStart(false);
        navigate('/connect');
        return;
      }

      if (result?.encryptedKeys) {
        setShowStart(false);
        navigate('/ord-wallet');
        return;
      }
      setShowStart(true);
    });
  }, [navigate]);

  useEffect(() => {
    const handleBeforeInstallPrompt = (event: Event) => {
      event.preventDefault();
      console.log('beforeinstallprompt event captured:', event);
      setDeferredPrompt(event);
      setShowStart(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);


  const handleInstallButtonClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
      });
    }
  };

  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const showInstallButton = deferredPrompt && !isIOS;

  const showAddToHomeScreenMessage = isIOS && !showInstallButton;

  return (
    <>
      {showStart ? (
        <Content>
          <PandaHead width="6rem" />
          <TitleText theme={theme} style={{ marginTop: '2rem' }}>Mikastamp</TitleText>
          <Text theme={theme} style={{ marginBottom: '2rem', fontWeight: 'bold' }}>
            A Gallery for your collection.
          </Text>
          <Button theme={theme} type="primary" label="Create New Gallery" onClick={() => navigate('/create-wallet')} />
          <Button theme={theme} type="secondary" label="Restore Collection" onClick={() => navigate('/restore-wallet')} />
          {showInstallButton && (
            <InstallButton theme={theme} type="primary" label="Install App" onClick={handleInstallButtonClick} />
          )}
          {showAddToHomeScreenMessage && (
            <Text theme={theme} style={{ marginTop: '1rem', textAlign: 'center' }}>
              To use Mikastamp as an app, tap the
              {' '}
              <img
                src="/share-ios.svg"
                alt="Share icon"
                style={{ verticalAlign: 'middle', marginLeft: '0.2rem', marginRight: '0.2rem', width: '1.2rem', height: '1.2rem' }}
              />
              {' '}
              icon and select "Add to Home Screen."
            </Text>
          )}
        </Content>
      ) : (
        <></>
      )}
    </>
  );
};