const images = [
    'https://mikastamp.com/wp-content/uploads/2023/12/to-the-moon-and-back.jpg',
    'https://mikastamp.com/wp-content/uploads/2024/04/Genesis-block_.jpg',
    'https://mikastamp.com/wp-content/uploads/2024/04/Satoshi-Nookamoto_.jpg',
    'https://mikastamp.com/wp-content/uploads/2023/12/Just-launch-it.jpg',
    'https://mikastamp.com/wp-content/uploads/2024/04/STM.jpg',
    'https://mikastamp.com/wp-content/uploads/2023/12/atomic-1.jpg',
    'https://mikastamp.com/wp-content/uploads/2023/12/a-new-dawn.jpg',
    'https://mikastamp.com/wp-content/uploads/2023/12/159S.jpg',
    'https://plugins.whatsonchain.com/api/plugin/main/c49f397547927a506f7843e5f18c49c2ef500c6125d5b121717b538cb11fbe9b/0',
    'https://mikastamp.com/wp-content/uploads/2024/04/Doges-bell-bag-30.jpg',
    // Add more image filenames as needed
];

function getRandomImage() {
    const randomIndex = Math.floor(Math.random() * images.length);
    return images[randomIndex];
}

export default getRandomImage;
