import React, { useState, useEffect } from 'react';
import { OrdinalTxo } from '../hooks/ordTypes';
import { Theme } from '../theme';
import { Text } from './Reusable';
import { Show } from './Show';
import { ImageOverlay, HtmlWrapper, FlexWrapper, FullScreenWrapper, FullScreenButton, FullScreenIframe } from './OrdinalStyles';
import { StyleSheetManager } from 'styled-components';

export type OrdinalProps = {
  theme: Theme;
  url: string;
  isTransfer?: boolean;
  selected?: boolean;
  size?: string;
  inscription: OrdinalTxo;
  onClick?: () => void;
};

export const Ordinal = (props: OrdinalProps) => {
  const { url, selected, isTransfer, size, inscription, theme, onClick } = props;
  const contentType = inscription?.origin?.data?.insc?.file?.type;

  const [isContentRendered, setIsContentRendered] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const DOUBLE_CLICK_INTERVAL = 300; // Adjust the interval as needed (in milliseconds)
  const [lastClickTime, setLastClickTime] = useState(0);

  useEffect(() => {
    const fetchAndCheckMetaTag = async () => {
      try {
        const response = await fetch(url);
        const htmlContent = await response.text();

        // Define an array of meta tags to check
        const metaTagsToCheck = [
          '<meta data="Mikastamp" content="Mikastamp">',
          '<meta name="Mikastamp" content="Mikastamp">',
          '<meta data="medium" content="Laser etching">',
          '<meta data="medium" content="acrylic">',
          '<meta data="medium" content="oil">',
          '<meta name="artist" content="christian godin">',
          '<meta name="artist" content="fabien sinapan">',
          '<meta name="artist" content="alessandra donato">',
          '<meta name="artist" content="agnes birgersson widin">',
          '<meta data="country" content="france">',
          '<meta data="country" content="sweden">',
          '<meta data="country" content="italy">',
          // Add more meta tags as needed
        ];

        // Check if any of the meta tags are present in the HTML content
        const containsAnyMetaTag = metaTagsToCheck.some(tag => htmlContent.includes(tag));

        setIsContentRendered(containsAnyMetaTag);
      } catch (error) {
        console.error('Error fetching or checking meta tag:', error);
        setIsContentRendered(false);
      }
    };

    fetchAndCheckMetaTag();
  }, [url]);

  useEffect(() => {
    // Inject Tawk.to script when the component mounts
    const script = document.createElement('script');
    script.src = 'https://embed.tawk.to/65c3d2ed0ff6374032ca6edb/1hm2evshh';
    script.async = true;
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    document.head.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.head.removeChild(script);
    };
  }, []);

  const handleDoubleClick = () => {
    const currentTime = new Date().getTime();
    const timeDifference = currentTime - lastClickTime;

    if (timeDifference < DOUBLE_CLICK_INTERVAL) {
      // Double click logic
      setIsExpanded(!isExpanded);
    } else {
      // Single click logic
      props.onClick && props.onClick();
    }

    // Update the last click time
    setLastClickTime(currentTime);
  };

  const renderContent = () => {
    if (!isContentRendered) {
      return null;
    }
    switch (true) {
      case contentType === 'text/html' || contentType === 'audio/mp3':
        return (
          <div style={{ position: 'relative', margin: '0.3rem', border: selected ? `0.1rem solid ${theme.lightAccent}` : undefined, borderRadius: '25px', transition: 'opacity 3.3s ease', }}>
            <HtmlWrapper
              $isExpanded={isExpanded}
              size={size}
              selected={selected}
              url={url}
              src={url}
              theme={theme}
              onClick={onClick}
              onDoubleClick={handleDoubleClick}
            />

            <ImageOverlay $isExpanded={isExpanded} onClick={handleDoubleClick} />
            <FullScreenWrapper $isExpanded={isExpanded}>
              <FullScreenButton onClick={() => setIsExpanded(false)}>
                Go Back app
              </FullScreenButton>
              <FullScreenIframe src={url} />
            </FullScreenWrapper>
          </div>
        );

      case contentType?.startsWith('image/'):
        return (
          <div
            style={{
              height: size ?? '20rem',
              width: size ?? '20rem',
              backgroundImage: `url(${url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '0.5rem',
              margin: '0.4rem',
              cursor: 'pointer',
              border: selected ? `0.8rem solid ${theme.lightAccent}` : undefined,
            }}
            onClick={onClick}
          />
        );
      case contentType === 'text/html':
        return (
          <div
            style={{
              height: size ?? '20rem',
              width: size ?? '20rem',
              backgroundImage: `url(${url})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '0.5rem',
              cursor: 'pointer',
              border: selected ? `0.8rem solid ${theme.lightAccent}` : undefined,
            }}
            onClick={onClick}
          >
            <Text theme={theme}>{inscription.origin?.data?.insc?.text}</Text>
          </div>
        );
      case contentType === 'application/json':
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: size ?? '9rem',
              width: size ?? '9rem',
              borderRadius: '0.5rem',
              position: 'relative',
              backgroundColor: theme.darkAccent,
              margin: '0.5rem',
              cursor: 'pointer',
              border: selected ? `0.3rem solid ${theme.lightAccent}` : undefined,
              overflow: 'auto',
            }}
            onClick={onClick}
          >
            <pre
              style={{
                fontFamily: 'DM Mono, monospace',
                fontSize: '0.65rem',
                color: theme.primaryButton,
                margin: '0',
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-word',
              }}
            >
              {JSON.stringify(inscription.origin?.data?.insc?.json, null, 2)}
            </pre>
          </div>
        );
      default:
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              overflowX: 'auto',
              backgroundImage: `url(https://mikastamp.com/wp-content/uploads/2023/12/art-studio-mikastamp.jpg)`,
              backgroundSize: 'contain',
              borderRadius: '0.5rem',
              margin: '10px',
              cursor: 'pointer',
              border: selected ? `0.3rem solid ${theme.lightAccent}` : undefined,
            }}
            onClick={onClick}
          >
            <Text theme={theme}>Display content not possible</Text>
          </div>
        );
    }
  };

  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'url'}>
      <FlexWrapper>
        {renderContent()}
        <Show when={!isTransfer}>
          <Text
            theme={theme}
            style={{
              margin: '0.35rem 0',
              cursor: 'pointer',
              color: '#ffffff',
              background: '#111111',
              borderRadius: '8px',
            }}
          />
        </Show>
      </FlexWrapper>
    </StyleSheetManager>
  );
};
